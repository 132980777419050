import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {AngularFireAnalytics} from '@angular/fire/analytics';

@Component({
  selector: 'app-service-two',
  templateUrl: './service-two.component.html',
  styleUrls: ['./service-two.component.scss']
})
export class ServiceTwoComponent implements OnInit {

  constructor(private meta: Meta,
              private titleService: Title,
              private translate: TranslateService,
              private analytics: AngularFireAnalytics) { }

    ngOnInit() {
      this.titleService.setTitle('VIP CPA | Corporate Taxes');
      this.meta.addTag({
        name: 'description',
        content: 'VIP CPA in downtown Montreal is a full-service accounting firm that can provide a variety of tax' +
          ' services including corporate tax services; VIP CPA can ct as your corporate tax accountant or provide' +
          ' corporate tax services for you.'
      }, true);
      this.meta.addTag({
        name: 'keywords',
        content: 'Accountant, Downtown, Montreal, Tax, Corporate Tax, least amount of corporate tax payable' +
          'Tax deduction, Payroll, Bookkeeping, Financial statement, Tax filing Montreal, ' +
          'Montreal accountant, Accountant Montreal, Accounting Montreal, Montreal tax accountant' +
          'Accountant in Montreal, Montreal Accounting, Montreal Accountants, Accountants Montreal' +
          'Tax Accountant Montreal, tax filing, tax filing canada, tax filing deadline, tax filing deadline' +
          'tax filing extension, tax filing online, tax filing deadline canada, tax filing near me, tax filing by mail,' +
          'tax filing by phone, tax filing canada deadline, tax filing cra, tax filing companies, tax filing expenses' +
          'tax filing for students, tax filing for international students, tax filing fees, tax filing for self employed' +
          'tax filing help, tax filing in montreal, tax filing in canada'
      }, true);
      this.meta.addTag({
        name: 'robots',
        content: 'index, follow'
      }, true);
      this.analytics.logEvent('select_content', {
        content_type: 'corporate-tax',
        item_id: 2
      });
      this.scrollToTop();
    }
  scrollToTop() {
    (function smoothscroll() {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

}
