import { Component, OnInit } from '@angular/core';
import {Store} from '@ngxs/store';
import {ChangeNavState} from '../../core/states/app.state';
import {Meta, Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {AngularFireAnalytics} from '@angular/fire/analytics';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(private store: Store, private meta: Meta,
              private titleService: Title,
              private translate: TranslateService,
              private analytics: AngularFireAnalytics) { }

  ngOnInit() {
    this.store.dispatch(new ChangeNavState('-'));
    this.titleService.setTitle('VIP CPA | Privacy Policy');
    this.meta.addTag({
      name: 'description',
      content: 'Privacy Policy of you at VIP CPA is important to us. ' +
        'This page provides detailed information of our privacy policy'
    }, true);
    this.meta.addTag({
      name: 'keywords',
      content: 'Accountant, Downtown, Montreal, Tax, Individual Taxation, Personal Tax, ' +
        'Tax deduction, Payroll, Bookkeeping, Financial statement, Tax filing Montreal, ' +
        'Montreal accountant, Accountant Montreal, Accounting Montreal, Montreal tax accountant' +
        'Accountant in Montreal, Montreal Accounting, Montreal Accountants, Accountants Montreal' +
        'Tax Accountant Montreal, tax filing, tax filing canada, tax filing deadline, tax filing deadline' +
        'tax filing extension, tax filing online, tax filing deadline canada, tax filing near me, tax filing by mail,' +
        'tax filing by phone, tax filing canada deadline, tax filing cra, tax filing companies, tax filing expenses' +
        'tax filing for students, tax filing for international students, tax filing fees, tax filing for self employed' +
        'tax filing help, tax filing in montreal, tax filing in canada, tax filing in US'
    }, true);
    this.meta.addTag({
      name: 'robots',
      content: 'index, follow'
    }, true);
    this.analytics.logEvent('PRIVACY_PAGE_event', {
      lang_browser: this.translate.getBrowserLang(),
      lang_user: this.translate.currentLang
    });
  }

}
