import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {AngularFireAnalytics} from '@angular/fire/analytics';

@Component({
  selector: 'app-service-one',
  templateUrl: './service-one.component.html',
  styleUrls: ['./service-one.component.scss']
})
export class ServiceOneComponent implements OnInit {

  constructor(private meta: Meta,
              private titleService: Title,
              private translate: TranslateService,
              private analytics: AngularFireAnalytics
  ) { }

  ngOnInit() {
    this.titleService.setTitle('VIP CPA | Personal Taxes');
    this.meta.addTag({
      name: 'description',
      content: 'You don’t have to worry about incorrectly filing your taxes. Located in downtown Montreal, VIP CPA offers' +
        ' you top-notch individual taxation help, you will be able to quickly and accurately process and file your tax return' +
        ' while providing the individual attention you deserve'
    }, true);
    this.meta.addTag({
      name: 'keywords',
      content: 'Accountant, Downtown, Montreal, Tax, Individual Taxation, Personal Tax, Tax deduction, ' +
        'Montreal accountant, Accountant Montreal, Accounting Montreal, Montreal tax accountant,' +
        'Accountant in Montreal, Montreal Accounting, Montreal Accountants, Accountants Montreal,' +
        'Tax Accountant Montreal tax filing, tax filing canada, tax filing deadline, tax filing deadline' +
        'tax filing extension, tax filing online, tax filing deadline canada, tax filing near me, tax filing by mail,' +
        'tax filing by phone, tax filing canada deadline, tax filing cra, tax filing companies, tax filing expenses' +
        'tax filing for students, tax filing for international students, tax filing fees, tax filing for self employed' +
        'tax filing help, tax filing in montreal, tax filing in canada, tax filing in US'
    }, true);
    this.meta.addTag({
      name: 'robots',
      content: 'index, follow'
    }, true);
    this.analytics.logEvent('select_content', {
      content_type: 'individual-tax',
      item_id: 1
    });
    this.scrollToTop();
  }

  scrollToTop() {
    (function smoothscroll() {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

}
