import { Component, OnInit } from '@angular/core';
import {ChangeNavState} from '../../core/states/app.state';
import {Store} from '@ngxs/store';
import {Router} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {AngularFireAnalytics} from '@angular/fire/analytics';

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.scss']
})
export class OurServicesComponent implements OnInit {

  constructor(private store: Store,
              private router: Router,
              private translate: TranslateService,
              private analytics: AngularFireAnalytics,
              private meta: Meta,
              private titleService: Title
  ) { }

  ngOnInit() {
    this.store.dispatch(new ChangeNavState('service'));
    this.analytics.logEvent('OUR_SERVICES_PAGE_event', {
      lang_browser: this.translate.getBrowserLang(),
      lang_user: this.translate.currentLang
    });
    this.titleService.setTitle('VIP CPA | Corporate Tax');
    this.meta.addTag({
      name: 'description',
      content: 'The field of corporate income taxation is changing rapidly, and it is important to have knowledgeable' +
        'professionals on your side to assist you in complying with new rules and regulations. As professional tax accountants ' +
        'in downtown Montreal, we work with countless clients throughout this city. We partner with many businesses to ensure they are ' +
        'accurately filing their quarterly and annual returns.'
    }, true);
    this.meta.addTag({
      name: 'keywords',
      content: 'Accountant, Downtown, Montreal, Tax, Corporate Tax, Tax deduction, ' +
        'Montreal accountant, Accountant Montreal, Accounting Montreal, Montreal tax accountant,' +
        'Accountant in Montreal, Montreal Accounting, Montreal Accountants, Accountants Montreal,' +
        'Tax Accountant Montreal'
    }, true);
    this.meta.addTag({
      name: 'robots',
      content: 'index, follow'
    }, true);
    this.scrollToTop();
  }

  routTo(r: string): void {
    this.router.navigate([r]);
  }

  scrollToTop() {
    (function smoothscroll() {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }
}
