export const APIKeys = {
   firebaseConfig: {
    apiKey: 'AIzaSyANOjU4YnmoRC9c-u03eAZgxc_SmgR-hbo',
    authDomain: 'vip-cpa.firebaseapp.com',
    databaseURL: 'https://vip-cpa.firebaseio.com',
    projectId: 'vip-cpa',
    storageBucket: 'vip-cpa.appspot.com',
    messagingSenderId: '644961324879',
    appId: '1:644961324879:web:2ba807c8110f1baf69bf3a',
    measurementId: 'G-C35GFX1WGE'
  },
  googleMapConfig: {
    apiKey: 'AIzaSyBPfXaZdlwf1cHxMIPxp4XRw25DsvnmJuU'
  }
};



